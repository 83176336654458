.notification {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 426px;
  height: 66px;
  cursor: pointer;
  z-index: 999999;

  &.notification-nw-mode {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 280px;

    .notification-icon {
      width: 40px;
    }

    .notification-text {
      width: 205px;
    }

    .notification-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      justify-content: center;
    }
  }
}

.notification-icon {
  width: 57px;
  color: #fff;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.new-background,
.closed-background,
.closedCustomer-background {
  background-color: #0d66d0 !important;
}

.transfer-background,
.connection-gain-background {
  background-color: #12805c !important;
}

.warning-background,
.error-background,
.connection-lost-background {
  background-color: #dc3545 !important;
}

.notification-content {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  color: #fff;
}

.notification-text {
  width: 337px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: adobe-clean;
}

.notification-title {
  font-weight: 400;
  line-height: 17px;
}

.notification-subtitle {
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
}

.remove-participant {
  position: fixed;
  bottom: 20px;
  width: 271px;
  height: 48px;
  cursor: pointer;
  z-index: 999999;

  &.remove-participant-nw-mode {
    left: 700;
    right: 20px;
    margin: 0 auto;
    width: auto;

    .remove-participant-text {
      display: inline;
    }

    .remove-participant-title {
      width: 161px;
      text-align: left;
      font: normal normal normal 14px/17px Adobe Clean;
      letter-spacing: 0px;
    }

    .remove-participant-close {
      margin-left: 20px;
      padding-left: 24px;
      height: 48px;
      background: none;
      color: #fff;
      border: none;
      border-left: 0.5px solid #FFFFFF33;
    }
  }
}

.remove-participant-icon {
  width: 12px;
  color: #fff;
}