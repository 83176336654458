.offline-heading {
  color: #2c2c2c;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
}

.offline-popover {
  top: 333px;
  left: 480px;
  border-radius: 4px;
  opacity: 1;
}

.offline-button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
}

.dark-text {
  color: #fff;
  font: normal normal normal 14px/17px Adobe Clean;
}

.incoming-call-text {
  text-align: left;
  font: normal normal normal 14px/20px Adobe Clean;
  letter-spacing: 0px;
  opacity: 1;
}

.incoming-call-text-key {
  color: var(--unnamed-color-4b4b4b);
  color: #4B4B4B;
}

.incoming-call-text-value {
  color: var(--unnamed-color-2c2c2c);
  color: #2C2C2C;
}

.incoming-call-subhead {
  color: var(--unnamed-color-4b4b4b);
  color: #4B4B4B;
  font: normal normal normal 14px/17px Adobe Clean;
}

.incoming-call-content {
  overflow: visible;
}

.incoming-call-button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-top: -20px;
}

.call-fail-button-close {
  margin-top: -20px;
}

.incoming-call-button-group>Button {
  background: var(--unnamed-color-1473e6) 0% 0% no-repeat padding-box;
  background: #1473E6 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  width: 98px;
  column-gap: 5px;
}

.telephony-call,
.telephony-call:hover {
  border-radius: 50%;
  background-color: #1473E6;
}

.missed-call {
  border-radius: 50%;
  background-color: #B90000;
}