.conversation-view-grid .chat-panel-closed-container section {
  height: calc(100vh - 160px);
  max-height: calc(100vh - 160px);
}

.chat-panel-closed-container section {
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
}

.voice-panel-closed-container section {
  height: calc(100vh - 210px);
  max-height: calc(100vh - 210px);
}

.chat-panel-container section + div {
  bottom: 0px !important;
  position: absolute;
}
.no-conv-container {
  height: 100%;
  display: flex;
  justify-content: center;
}
.no-conversations {
  color: #6e6e6e;
  text-align: center;
  letter-spacing: 0px;
  margin-top: calc(50vh - 120px);
  p {
    margin: 0px;
    height: 33px;
    width: 200px;
  }
}
.transfer-in-progress section + div {
  pointer-events: none;
}
.transfer-in-progress section + div:hover {
  cursor: not-allowed;
}
