.agent-desktop-scroll-light *::-webkit-scrollbar {
  width: 0.5rem;
}
.agent-desktop-scroll-light *::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 15px;
}

.agent-desktop-scroll-dark *::-webkit-scrollbar {
  width: 0.5rem;
}
.agent-desktop-scroll-dark *::-webkit-scrollbar-thumb {
  background: #000 !important;
  border-radius: 15px;
}
:root {
  --light-primary-text: #4b4b4b;
  --light-secondary-text: #cacaca;
  --light-primary-bg: #ffffff;
  --light-secondary-bg: #f5f5f5;

  --dark-primary-bg: #323232;
  --dark-secondary-bg: #252525;
  --dark-primary-text: #ffffff;
  --dark-secondary-text: #b3b3b3;

  --bold-font-weight: 700;
  --Regular-font-weight: 300;
}

main {
  flex-grow: 1;
}
.agent-desktop {
  display: flex;
  height: 100vh;
  box-sizing: border-box;
  overflow-y: hidden;
}
.ccp-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(60, 60, 60, 0.5);
}

body ::selection {
  background-color: #00d694;
}
