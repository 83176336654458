.conversation-history {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  padding-bottom: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  height: 100%;
}
.conversation-history > div,
.conversation-history-dark > div {
  border: none;
}
.conversation-history > div > hr:last-of-type {
  box-shadow: 0px 2px 4px #aaa !important;
}
.conversation-history-dark {
  flex: 1;
  overflow: hidden;
  background-color: #2f2f2f;
  padding-bottom: 20px;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
  height: 100%;
}
.conversation-history-dark > div > hr:last-of-type {
  box-shadow: none !important;
}
.conversation-history-dark > div > div:last-of-type {
  height: auto;
}
.conversation-history > div > div:last-of-type {
  height: auto;
}

.conversation-history .scrollbar-container .ps__rail-y,
.conversation-history-dark .scrollbar-container .ps__rail-y {
  z-index: 3 !important;
}

.conversation-history .scrollbar-container .ps__thumb-y,
.conversation-history-dark .scrollbar-container .ps__thumb-y {
  z-index: 4 !important;
}
