.ru-management {
 flex: 1 2;
 flex-direction: column;
 height: calc(100% - 16px)!important;
}

.ru-management-top,
.ru-management-top-dark {
  border-radius: 5px;
  height: 215px;
  max-width: 450px;
  overflow: hidden !important;
}

.ru-management-top-expanded {
  height: calc(100% - 9px) !important;
}

.ru-management-basement,
.ru-management-basement-dark {
  height: calc(100% - 246px)!important;
  margin-top: 10px;
  position: relative;
  z-index: 99;
}

.ru-management-top,
.ru-management-basement {
  border-left: 1px solid #e1e1e1;
  border: 1px solid #e1e1e1;
  background-color: white;
  border-radius: 5px;
  padding-bottom: 10px;
}

.ru-management-top-dark,
.ru-management-basement-dark {
  border-left: 1px solid #4a4a4a;
  background: #323232;
  border: 1px solid #5a5a5a;
  background-color: #2f2f2f;
  padding-bottom: 10px;
  border-radius: 5px;
}

.ru-management-top-dark {
  border-bottom: 1px solid #4a4a4a;
  background-color: transparent;
}
