.header {
  pointer-events: none;
  position: absolute;
  padding: 20px;
}
.login-group {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.image-container {
  pointer-events: none;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

a:link {
  text-decoration: none;
}

.text-container-welcome {
  margin-top: 0.5rem;
  display: flex;
  letter-spacing: 0;
  font-family: adobe-clean, sans-serif;
  justify-content: center;
  align-items: center;
}

.text-container-agent {
  margin-top: 1.5rem;
  display: flex;
  letter-spacing: 0;
  opacity: 1;
  font-family: adobe-clean, sans-serif;
  font-size: 24px;
  font-weight: 800;
  font-style: normal;
}

p {
  line-height: normal;
}

.login-btn {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

Button:hover {
  cursor: pointer;
}

.light-mode-text-color {
  color: #2c2c2c;
}

.light-mode-welcome-text-color {
  color: #4b4b4b;
}
