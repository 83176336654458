.flyout-panel-container {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}
.flyout-panel-child {
  border: 1px solid;
  border-radius: 5px;
  margin: 10px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
