.widget-container {
  display: flex;
  flex-direction: row;
  height: calc(100% - 10px);
  width: calc(100% - 21px);
  padding-top: 10px;
  padding-right: 20px;
  // margin-right: 0 !important;
}

.widget-container>* {
  margin-left: 10px;
}

.view-widget-container {
  margin-top: 2px;
  background-color: #f5f5f5;
  width: 100%;
  height: calc(100vh - 82px) !important;
}

.view-widget-container-dark {
  margin-top: 2px;
  background-color: #323232;
  width: 100%;
  height: calc(100vh - 82px) !important;
}

.widget-container-light {
  border-left: 1px solid #e1e1e1;
  background: #f5f5f5;

  .widget-items {
    border: 1px solid #e1e1e1;
    background-color: #ffffff;
  }
}

.widget-container-dark {
  border-left: 1px solid #4a4a4a;
  background: #323232;

  .widget-items {
    border: 1px solid #5a5a5a;
    background-color: #2f2f2f;
  }
}

// container widget layout
.widget-items {
  height: calc(100% - 35px);
  border-radius: 5px;
  padding-bottom: 20px;
}

// TODO: Temporary styles for visual purpose, to be removed later
.toolbar,
.nav-menu {
  color: white;
}

.Flyout-panel {
  word-break: break-word;
}

.basic-dark {
  background: #323232;
}

.basic-light {
  background: #f5f5f5;
}

.flyout-dark {
  background: #252525;
}

.flyout-light {
  background: #eaeaea;
}

.chat-container {
  margin-top: 2px;
  position: relative;
}

//center the content
.no-widget-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-widget {
  color: #6e6e6e;
  text-align: center;
  letter-spacing: 0px;

  p {
    margin: 0px;
  }
}

// Top bar icons
.icon-text-center {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.8rem;
}

.text-center {
  margin-right: auto;
}

.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin: 0.5rem;
  color: #6e6e6e;
  background-color: #f5f5f5;
  border: 3px solid #6e6e6e;
}

.icon-button-active {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin: 0.5rem;
  color: #f5f5f5;
  background-color: #1473e6;
  border: 3px solid #1473e6;
}

.icon-button:hover {
  color: #f5f5f5;
  background-color: #6e6e6e;
  border: 3px solid #6e6e6e;
}

.icon-button-active:hover {
  color: #f5f5f5;
  background-color: #6e6e6e;
  border: 3px solid #6e6e6e;
}

.header-topbar>section,
.header-topbar>div>section {
  display: flex;
  flex-direction: column;
}

.hide-toolbar {
  display: none;
}

.navbar-cus-dark-scroll-container>.ps__rail-x:hover,
.navbar-cus-dark-scroll-container>.ps__rail-y:hover,
.navbar-cus-dark-scroll-container>.ps__rail-x:focus,
.navbar-cus-dark-scroll-container>.ps__rail-y:focus,
.navbar-cus-dark-scroll-container>.ps__rail-x.ps--clicking,
.navbar-cus-dark-scroll-container>.ps__rail-y.ps--clicking {
  background-color: #252525 !important;
  width: 6px !important;
}

.navbar-cus-dark-scroll-container .ps__thumb-y {
  background-color: #000 !important;
  width: 6px !important;
}

.oc-con-dark-custom-scrollbar>.ps__rail-x:hover,
.oc-con-dark-custom-scrollbar>.ps__rail-y:hover,
.oc-con-dark-custom-scrollbar>.ps__rail-x:focus,
.oc-con-dark-custom-scrollbar>.ps__rail-y:focus,
.oc-con-dark-custom-scrollbar>.ps__rail-x.ps--clicking,
.oc-con-dark-custom-scrollbar>.ps__rail-y.ps--clicking {
  background-color: #323232 !important;
  width: 6px !important;
}

.oc-con-dark-custom-scrollbar .ps__thumb-y {
  background-color: #000 !important;
  width: 6px !important;
}

#icon_-_first_time_customer {
  vertical-align: middle !important;
}

.conversation-view-grid {
  .chat-container {
    .chat-panel-container section {
      height: calc(100vh - 340px);
      min-height: calc(100vh - 340px);
    }

    .no-conv-container {
      .no-conversations {
        align-items: center;
        margin: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 60px;
      }
    }
  }

  .header-topbar .chat-topbar {
    section:first-child {
      height: 4px !important;
      min-height: 43px !important;
      padding-top: 15px;

      div:nth-of-type(1) {
        padding: 0 20px;
      }
    }
  }

  .hide-toolbar {
    display: block;
  }

  .view-widget-container,
  .view-widget-container-dark {
    height: calc(100vh - 142px) !important;
  }
}

.list-view-grid {
  .header-topbar .chat-topbar {
    width: 0;
    height: 0;
  }

  .flyout-light,
  .flyout-dark,
  .basic-light,
  .basic-dark,
  .view-widget-container,
  .view-widget-container-dark {
    width: 0;
    height: 0;
  }
}