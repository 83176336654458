// Light node css
.spectrum--light .spectrum-Popover {
  background-color: rgb(255, 255, 255);
  border-color: rgb(202, 202, 202);
  box-shadow: '0 1px 4px rgb(0 0 0 / 15%)';
}
.spectrum--light .spectrum-ActionButton--quiet {
  background-color: transparent;
  border-color: transparent;
}
.spectrum--light .spectrum-Textfield {
  background-color: rgb(255, 255, 255);
  border-color: rgb(225, 225, 225);
  color: rgb(75, 75, 75);
}
.spectrum--light .spectrum-Calendar-prevMonth {
  color: rgb(110, 110, 110);
}
.spectrum--light .spectrum-Calendar-nextMonth {
  color: rgb(110, 110, 110);
}
.spectrum--light .spectrum-Calendar-dayOfWeek {
  color: rgb(110, 110, 110);
}
.spectrum--light .spectrum-Calendar-date:hover {
  color: rgb(44, 44, 44);
}
.spectrum--light .spectrum-Calendar-date:hover:not(.is-selection-end):not(.is-selection-start):before {
  background: rgba(44, 44, 44, 0.06);
}
.spectrum--light .spectrum-Calendar-date:hover.is-selected {
  color: rgb(44, 44, 44);
}
.spectrum--light .spectrum-Calendar-date:hover.is-selected:not(.is-selection-end):not(.is-selection-start):before {
  background: rgba(20, 115, 230, 0.2);
}
.spectrum--light .spectrum-Calendar-date:hover.is-range-selection:before {
  background: rgba(20, 115, 230, 0.2);
}
.spectrum--light .spectrum-Calendar-date:active {
  background-color: rgba(44, 44, 44, 0.1);
}
.spectrum--light .spectrum-Calendar-date.is-selected {
  color: rgb(44, 44, 44);
  background: rgba(20, 115, 230, 0.1);
}
.spectrum--light .spectrum-Calendar-date.is-selected:not(.is-range-selection) {
  background: rgba(20, 115, 230, 0.2);
}
.spectrum--light .spectrum-Calendar-date.is-today {
  color: rgb(75, 75, 75);
  border-color: rgb(75, 75, 75);
}
.spectrum--light .spectrum-Calendar-date.is-today:before {
  border-color: rgb(75, 75, 75);
}
.spectrum--light .spectrum-Calendar-date.is-today:hover.is-selected:not(.is-range-selection):before {
  background: rgba(20, 115, 230, 0.2);
}
.spectrum--light .spectrum-Calendar-date.is-today.is-disabled {
  color: rgb(179, 179, 179);
  border-color: rgb(202, 202, 202);
}
.spectrum--light .spectrum-Calendar-date.is-today.is-disabled:before {
  border-color: rgb(202, 202, 202);
}
.spectrum--light .spectrum-Calendar-date.is-focused:not(.is-range-selection) {
  background: rgba(44, 44, 44, 0.06);
  border-color: rgb(38, 128, 235);
  color: rgb(44, 44, 44);
}
.spectrum--light .spectrum-Calendar-date.is-focused:not(.is-range-selection).is-today {
  border-color: rgb(38, 128, 235);
}
.spectrum--light .spectrum-Calendar-date.is-focused:not(.is-range-selection):active,
.spectrum--light .spectrum-Calendar-date.is-focused:not(.is-range-selection).is-selected {
  color: rgb(44, 44, 44);
  background: rgba(20, 115, 230, 0.2);
  border-color: rgb(38, 128, 235);
}
.spectrum--light .spectrum-Calendar-date.is-focused.is-selected:before {
  background: rgba(20, 115, 230, 0.2);
}
.spectrum--light .spectrum-Calendar-date.is-focused.is-range-selection:before {
  background: rgba(20, 115, 230, 0.2);
}
.spectrum--light .spectrum-Calendar-date.is-disabled {
  color: rgb(179, 179, 179);
}
.spectrum--light .spectrum-Calendar-date.is-selection-start,
.spectrum--light .spectrum-Calendar-date.is-selection-end {
  color: rgb(44, 44, 44);
}
.spectrum--light .spectrum-Calendar-date.is-selection-start:after,
.spectrum--light .spectrum-Calendar-date.is-selection-end:after {
  background-color: rgba(20, 115, 230, 0.1);
}
.spectrum--light .spectrum-Calendar-date.is-selection-start.is-disabled,
.spectrum--light .spectrum-Calendar-date.is-selection-end.is-disabled {
  color: rgb(179, 179, 179);
}
.spectrum--light .spectrum-FieldButton {
  color: rgb(75, 75, 75);
  background-color: rgb(250, 250, 250);
  border-color: rgb(225, 225, 225);
}

// Dark Mode css
.spectrum--dark .spectrum-Popover {
  background-color: rgb(37, 37, 37);
  border-color: rgb(90, 90, 90);
}
.spectrum--dark .spectrum-FieldButton {
  color: rgb(227, 227, 227);
  background-color: rgb(47, 47, 47);
  border-color: rgb(74, 74, 74);
}
.spectrum--dark .spectrum-Textfield {
  background-color: rgb(37, 37, 37);
  border-color: rgb(74, 74, 74);
  color: rgb(227, 227, 227);
}
.spectrum--dark .spectrum-Calendar-prevMonth {
  color: rgb(185, 185, 185);
}
.spectrum--dark .spectrum-Calendar-nextMonth {
  color: rgb(185, 185, 185);
}
.spectrum--dark .spectrum-Calendar-dayOfWeek {
  color: rgb(185, 185, 185);
}
.spectrum--dark .spectrum-Calendar-date:hover {
  color: rgb(255, 255, 255);
}
.spectrum--dark .spectrum-Calendar-date:hover:not(.is-selection-end):not(.is-selection-start):before {
  background: rgba(255, 255, 255, 0.07);
}
.spectrum--dark .spectrum-Calendar-date:hover.is-selected {
  color: rgb(255, 255, 255);
}
.spectrum--dark .spectrum-Calendar-date:hover.is-selected:not(.is-selection-end):not(.is-selection-start):before {
  background: rgba(55, 142, 240, 0.25);
}
.spectrum--dark .spectrum-Calendar-date:hover.is-range-selection:before {
  background: rgba(55, 142, 240, 0.25);
}
.spectrum--dark .spectrum-Calendar-date:active {
  background-color: rgba(255, 255, 255, 0.1);
}
.spectrum--dark .spectrum-Calendar-date.is-selected {
  color: rgb(255, 255, 255);
  background: rgba(55, 142, 240, 0.15);
}
.spectrum--dark .spectrum-Calendar-date.is-selected:not(.is-range-selection) {
  background: rgba(55, 142, 240, 0.25);
}
.spectrum--dark .spectrum-Calendar-date.is-today {
  color: rgb(227, 227, 227);
  border-color: rgb(227, 227, 227);
}
.spectrum--dark .spectrum-Calendar-date.is-today:before {
  border-color: rgb(227, 227, 227);
}
.spectrum--dark .spectrum-Calendar-date.is-today:hover.is-selected:not(.is-range-selection):before {
  background: rgba(55, 142, 240, 0.25);
}
.spectrum--dark .spectrum-Calendar-date.is-today.is-disabled {
  color: rgb(110, 110, 110);
  border-color: rgb(90, 90, 90);
}
.spectrum--dark .spectrum-Calendar-date.is-today.is-disabled:before {
  border-color: rgb(90, 90, 90);
}
.spectrum--dark .spectrum-Calendar-date.is-focused:not(.is-range-selection) {
  background: rgba(255, 255, 255, 0.07);
  border-color: rgb(38, 128, 235);
  color: rgb(255, 255, 255);
}
.spectrum--dark .spectrum-Calendar-date.is-focused:not(.is-range-selection).is-today {
  border-color: rgb(38, 128, 235);
}
.spectrum--dark .spectrum-Calendar-date.is-focused:not(.is-range-selection):active,
.spectrum--dark .spectrum-Calendar-date.is-focused:not(.is-range-selection).is-selected {
  color: rgb(255, 255, 255);
  background: rgba(55, 142, 240, 0.25);
  border-color: rgb(38, 128, 235);
}
.spectrum--dark .spectrum-Calendar-date.is-focused.is-selected:before {
  background: rgba(55, 142, 240, 0.25);
}
.spectrum--dark .spectrum-Calendar-date.is-focused.is-range-selection:before {
  background: rgba(55, 142, 240, 0.25);
}
.spectrum--dark .spectrum-Calendar-date.is-disabled {
  color: rgb(110, 110, 110);
}
.spectrum--dark .spectrum-Calendar-date.is-selection-start,
.spectrum--dark .spectrum-Calendar-date.is-selection-end {
  color: rgb(255, 255, 255);
}
.spectrum--dark .spectrum-Calendar-date.is-selection-start:after,
.spectrum--dark .spectrum-Calendar-date.is-selection-end:after {
  background-color: rgba(55, 142, 240, 0.15);
}
.spectrum--dark .spectrum-Calendar-date.is-selection-start.is-disabled,
.spectrum--dark .spectrum-Calendar-date.is-selection-end.is-disabled {
  color: rgb(110, 110, 110);
}

.all-conversation-page {
  overflow: hidden;
  .agent-desktop {
    overflow-x: hidden;
  }
}
div[aria-label='All conversations table'] div:nth-child(2) {
  overflow-x: hidden !important;
}

.agent-desktop * {
  font-family: adobe-clean !important;
}
